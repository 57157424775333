import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
import { ElMessage } from "element-plus";
import i18n from "@/core/plugins/i18n";

let lang = localStorage.getItem("lang");
if (!lang) {
  lang = "hk";
}
let region = localStorage.getItem("region");
if (!region) {
  region = "hk";
}
let sport = localStorage.getItem("sport");
if (!sport) {
  sport = "tennis";
}


const routes: Array<RouteRecordRaw> = [
  {
    path: "/:lang/:region/:game",
    //path: "/:lang/:region/:sport/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "bookkeep",
        name: "bookkeep",
        component: () => import("@/views/pages/offline/bookkeep.vue"),
      },
      
    ],
  },
  {
    path: "/",
    //redirect: "/dashboard",
    redirect: (to) => ({ name: "bookkeep", params: { lang: "hk", region: "hk", game: "poker"} }),
    //redirect: (to) => ({ name: "tourSeries", params: { lang: "hk" } }),
    
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  //store.dispatch(Actions.VERIFY_AUTH);
  store.dispatch("setPrevRoute", from);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next();
});

export default router;
